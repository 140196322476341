import React from 'react';
import useStorage from 'src/utils/useStorage';
import Context from './Context';

const StoreProvider = ({ children }) => {
  const [token, setToken] = useStorage('token');
  const [user, setUser] = useStorage('user');
  const [showLinearProgress, setShowLinearProgress] = useStorage('showLinearProgress');
  const [cacheApp, setCacheApp] = useStorage('cacheApp');
  const [minimizeSidebar, setMinimizeSidebar] = React.useState(false);
  
  return (
    <Context.Provider
      value={{
        token, setToken,
        user, setUser,
        showLinearProgress, setShowLinearProgress,
        cacheApp, setCacheApp,
        minimizeSidebar, setMinimizeSidebar
      }}
    >
      {children}
    </Context.Provider>
  )
}


export default StoreProvider;
