import { createContext } from 'react';

const StoreContext = createContext({
  token: null, setToken: () => {},
  user: null, setUser: () => {},
  showLinearProgress: false, setShowLinearProgress: () => {},
  minimizeSidebar: false, setMinimizeSidebar: () => {},
  cacheApp: {enums: {}}, setCacheApp: () => {}  
});

export default StoreContext;
